import * as React from "react"

// Constants
const ONE_TRUST_ID = "f3490e9b-e3c6-4897-bcee-29b61d0d6460"

// Components
const cookieDelayShowBannerMobile = (
  <script
    dangerouslySetInnerHTML={{
      __html: `function showBannerCP(){document.getElementById("onetrust-banner-sdk").style.display="flex",window.removeEventListener("touchstart",showBannerCP),window.removeEventListener("scroll",showBannerCP),window.removeEventListener("click",showBannerCP),window.removeEventListener("mousemove",showBannerCP)}window.innerWidth<=700&&document.addEventListener("DOMNodeInserted",(function(e){"onetrust-banner-sdk"==e.target.id&&(document.getElementById("onetrust-banner-sdk").style.display="none",window.addEventListener("touchstart",showBannerCP),window.addEventListener("scroll",showBannerCP),window.addEventListener("click",showBannerCP),window.addEventListener("mousemove",showBannerCP),setTimeout((function(){showBannerCP()}),5e3))}),!1);`,
    }}
  />
)

const oneTrustSDK = (
  <script
    delay="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    type="text/javascript"
    charset="UTF-8"
    data-domain-script={ONE_TRUST_ID}
    rel="preconnect"
    async
  />
)

const dataLayer = (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'OneTrustImplemented',
        'OneTrustStatus': 'OneTrustOk'
      })`,
    }}
  />
)

const oneTrustWrapper = (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: "function OptanonWrapper() { }",
    }}
  />
)

const delayScript = (
  <script
    dangerouslySetInnerHTML={{
      __html: `const autoLoadDuration = 3;
      const eventList = ["keydown", "mousemove", "wheel", "touchmove", "touchstart", "touchend"];
    
      const autoLoadTimeout = setTimeout(runScripts, autoLoadDuration * 1000);
      
      eventList.forEach(function(event) {
          window.addEventListener(event, triggerScripts, { passive: true })
      });
      
      function triggerScripts() {
          runScripts();
          clearTimeout(autoLoadTimeout);
          eventList.forEach(function(event) {
              window.removeEventListener(event, triggerScripts, { passive: true });
          });
      }
      
      function runScripts() {
          document.querySelectorAll("script[delay]").forEach(function(scriptTag) {
              scriptTag.setAttribute("src", scriptTag.getAttribute("delay"));
          });
      }`,
    }}
  ></script>
)

// https://github.com/filamentgroup/loadCSS
const loadCSS = (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `!function(e){"use strict";var t=function(t,n,o,r){var i,a=e.document,d=a.createElement("link");if(n)i=n;else{var f=(a.body||a.getElementsByTagName("head")[0]).childNodes;i=f[f.length-1]}var l=a.styleSheets;if(r)for(var s in r)r.hasOwnProperty(s)&&d.setAttribute(s,r[s]);d.rel="stylesheet",d.href=t,d.media="only x",function e(t){if(a.body)return t();setTimeout((function(){e(t)}))}((function(){i.parentNode.insertBefore(d,n?i:i.nextSibling)}));var u=function(e){for(var t=d.href,n=l.length;n--;)if(l[n].href===t)return e();setTimeout((function(){u(e)}))};function c(){d.addEventListener&&d.removeEventListener("load",c),d.media=o||"all"}return d.addEventListener&&d.addEventListener("load",c),d.onloadcssdefined=u,u(c),d};"undefined"!=typeof exports?exports.loadCSS=t:e.loadCSS=t}("undefined"!=typeof global?global:this)`,
    }}
  />
)

// This stylesheet will be loaded in the browser asyncronously due to the loadCSS script.
// In this particular case, we defer webfonts since they are not necesary to
// render de page, this way they will not block the page render process.
const webfonts = (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: 'loadCSS("/fonts/font-face.css")',
    }}
  />
)

const onRenderBody = ({ setBodyAttributes, setHeadComponents, pathname }) => {
  const bodyAttributes = pathname === "ax-editor" ? null : { id: "iesite" }
  const headComponents =
    pathname === "ax-editor"
      ? [loadCSS, webfonts]
      : [
          loadCSS,
          webfonts,
          delayScript,
          oneTrustSDK,
          oneTrustWrapper,
          dataLayer,
          cookieDelayShowBannerMobile,
        ]

  setBodyAttributes(bodyAttributes)
  setHeadComponents(headComponents)
}

export default {
  onRenderBody,
}
