import React from "react"
import { Stack, Text, Box } from "@sqymagma/elements"
import { ThemeProvider } from "styled-components"
import { useStyledSubTheme } from "@hooks"
import { Link } from "@components"
import { themeGet as theme } from "@styled-system/theme-get"

const HeaderCTAContainer = ({ display, hs, links, variant, menuBg, unPin }) => {
  return (
    <Stack
      width="100%"
      vs={{ default: "xs", m: 0 }}
      mb={{ default: "m", xl: "xs" }}
      flexDirection={{ default: "column", m: "row" }}
      justifyContent={{ default: "flex-start", xl: "flex-end" }}
      display={display}
      hs={hs}
    >
      <ThemeProvider
        theme={useStyledSubTheme({
          name:
            variant === "transparent" && !menuBg?.open
              ? unPin
                ? "default"
                : "accent"
              : "default",
        })}
      >
        {links?.map(
          (link, idx) =>
            link.text && (
              <Box
                css={`
                  a {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                  }
                  a:focus-visible::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border: 5px solid ${theme("colors.interactive01Focus")};
                  }
                `}
              >
                <Link
                  key={idx}
                  {...link.url}
                  external={false}
                  variant={{ default: "buttonM", m: "navCta" }}
                  appareance={link.style}
                >
                  <Text textStyle="buttonS">{link.text}</Text>
                </Link>
              </Box>
            )
        )}
      </ThemeProvider>
    </Stack>
  )
}

export default HeaderCTAContainer
