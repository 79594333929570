import _components from "@components"
import modules from "@modules"

import browser from "../builder.browser"
import ssr from "../builder.ssr"

// ADAPTED CORE ELEMS
import { mapLibrary, SiteProvider } from "@adapters"

// TEMPLATES
import * as templates from "@templates"

const components = mapLibrary({ ..._components, ...modules })

const ONE_TRUST_ID_IENYC = "01912286-642e-7fe5-b891-028de387e6d1"
const IENYC_DOMAINS = [
  "qa.thesaurus.ie-nyc.org",
  "qa.ie-nyc.org",
  "qa.landing.ie-nyc.org",
  "www.landing.ie-nyc.org",
  "landing.ie-nyc.org",
  "www.ie-nyc.org",
  "ie-nyc.org",
  "thesaurus.ie-nyc.org",
]

function getCookie(cname) {
  var name = cname + "="
  var decodedCookie =
    typeof window !== "undefined" && decodeURIComponent(document?.cookie)
  var ca = decodedCookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

function changeScript() {
  if (typeof window !== "undefined") {
    if (getCookie("OptanonConsent")) {
      document.querySelectorAll("script[delay]").forEach(function (scriptTag) {
        scriptTag.setAttribute("src", scriptTag.getAttribute("delay"))
      })
    }

    const currentDomain =
      typeof window !== "undefined" &&
      window?.location &&
      window?.location?.hostname

    const scriptTags = document?.querySelectorAll("script[delay]")

    scriptTags.forEach(function (scriptTag) {
      if (IENYC_DOMAINS.includes(currentDomain)) {
        scriptTag.setAttribute("data-domain-script", ONE_TRUST_ID_IENYC)
      }
    })
  }
}

function setNorCookie() {
  if (typeof window !== "undefined") {
    // set cookie to know if the user is new or recurrent
    if (!sessionStorage.getItem("prevNor")) {
      if (document.cookie.includes("nor=")) {
        var norValue = "r"
      } else {
        var norValue = "n"
      }
      const norExpires = new Date()
      norExpires.setTime(norExpires.getTime() + 360 * 24 * 60 * 60 * 1000)
      document.cookie =
        "nor=" + norValue + "; expires=" + norExpires + "; path=/"
      sessionStorage.setItem("prevNor", true)
    }
  }
}

changeScript()
setNorCookie()

//Dimensions
const generateAutomaticDimensions = props => ({})

export {
  templates,
  SiteProvider,
  components,
  ssr,
  browser,
  generateAutomaticDimensions,
}
