import { Link } from "@components"
import { SCHOOLS } from "@constants"
import MaxWidth from "@elements/MaxWidth"
import { usePage, useStyledSubTheme } from "@hooks"
import { Box, Flex } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import DesktopSubMenu from "./DesktopSubMenu"
import styled, {
  createGlobalStyle,
  css,
  ThemeProvider,
} from "styled-components"
import NavContainer from "./NavContainer"
import TopZIndex from "@constants/TopZIndex"
import { GriddoLink } from "@griddo/core"
import { LdJson, useI18n } from "@hooks"

const Wrapper = styled.div`
  transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  ${p =>
    p.unPin &&
    css`
      height: 56px;
    `};

  ${theme("mq.m")} {
    height: 96px;
  }

  ${theme("mq.xl")} {
    height: 131px;
  }
`

const Outter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  z-index: ${TopZIndex.header.outterDefault};
  background-color: ${p =>
    p.menuBg || p.variant !== "transparent"
      ? theme("colors.ui01")
      : "transparent"};

  transition: background-color 0.3s ease-in-out;

  ${p =>
    p.unPin &&
    css`
      z-index: ${TopZIndex.header.outter1};
      background-color: ${theme("colors.ui01")};
      border-bottom: 1px solid ${theme("colors.ui03")};
    `}

  ${p =>
    p.unFix &&
    css`
      z-index: ${TopZIndex.header.outter2};
      background-color: ${p =>
        p.variant === "transparent" && !p.menuBg ? "transparent" : "white"};
    `}
`

const LockBody = createGlobalStyle`
  body {
    ${p =>
      p.lock &&
      css`
        overflow: hidden;
      `}
      ${theme("mq.xl")}{
        overflow: visible;
      }
  }

  @media screen and (max-width: 1215px) {
    .headroom-wrapper .headroom {
      transform: none !important;
    }
  }
`

const HambMenu = styled.div`
  margin-left: ${p => (p.ml === "auto" ? "auto" : "0px")};
  ${p =>
    p.variant !== "transparent" &&
    css`
      &:hover {
        svg * {
          fill: ${theme("colors.icons01")};
        }
      }
      svg * {
        fill: ${theme("colors.icons01")};
      }
    `}

  ${p =>
    p.unPin &&
    css`
      svg * {
        fill: ${theme("colors.icons01")};
      }
    `}
`

const HambButton = styled.button`
  border: 0;
  cursor: pointer;
  background: none;
  line-height: 0;
  padding-left: 0;
  &:hover {
    svg * {
      opacity: 0.5;
    }
  }

  ${p =>
    p.unPin &&
    css`
      &:hover {
        svg * {
          fill: ${theme("colors.icons01")};
        }
      }
    `}
`

const NavigationWrapper = styled(Box)`
  ${theme("mq.xl")} {
    width: 100%;
    background-color: inherit;
    align-items: center;
  }
`

const MobileMenuContainer = styled(Flex)`
  overflow: hidden;
`

const MobileAnimatedContainer = styled(motion.div)`
  display: flex;
  position: fixed;
  overflow: hidden;
  width: 100%;
  right: 0;
  top: 0;
  background-color: ${theme("colors.overlay01")};
`

const CloseNav = styled(motion.div)`
  cursor: pointer;
  width: 32px;
  height: 64px;
  background-color: ${theme("colors.ui01")};
  position: relative;
  z-index: ${TopZIndex.header.closeNav};
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme("mq.m")} {
    width: 64px;
  }
`

const DesktopSubMenuWrapper = styled(Box)`
  opacity: ${p => (p.menuBg ? 1 : 0)};
  height: ${p => (p.menuBg ? "100%" : "0")};
  transition: 0.3s all cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
`

const MenuContent = ({
  sticky,
  customSetMenuBg,
  isMobile,
  menuBg,
  variant,
  logo,
  unPin,
  unFix,
  anchorID,
  school,
  showBotIcon,
  mainMenu,
  primaryLink,
  secondaryLink,
  pageLanguages,
  getTranslation,
  languageId,
  showSocialMedia,
  topMenu,
  botUrl,
  socialsArray,
}) => {
  const header = React.useRef()
  const [open, setOpen] = React.useState(false)
  // Language
  const { getNestedTranslation: t } = useI18n()
  const prefixAriaLabel = "icons.ariaLabel."
  const bot = t(`${prefixAriaLabel}bot`, "Bot")
  const openMenu = t(`${prefixAriaLabel}openMenu`, "Open menu")
  const closeMenu = t(`${prefixAriaLabel}closeMenu`, "Close menu")
  const goToHome = t(`${prefixAriaLabel}goToHome`, "Go to home page")

  const mobileTheme = useStyledSubTheme({ name: "accent" })

  // Checking if link or any of its children is active
  const { fullUrl, fullPath } = usePage()

  const isActiveLink = link => {
    const linkUrl = link?.url?.linkToURL
    const linkAnchor = link?.url?.anchor
    const children = link?.children
    let result = false

    if (typeof window !== "undefined" && window.location.hash) {
      result =
        `#${linkAnchor}` === window.location.hash &&
        linkUrl.replace(window.location.hash, "") === fullUrl
    } else {
      result = linkUrl === fullUrl
    }

    if (!result && children?.length) {
      result = children.some(child => isActiveLink(child))
    }
    return result
  }

  const getLogoUrl = () => {
    const domain = fullPath?.domainUrl
    const language =
      fullPath?.language !== "/" ? fullPath?.language + "/" : fullPath?.language
    return domain + language
  }

  const onkeyDown = event => {
    if (event.key === "Escape") {
      setOpen(false)
    }
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("keydown", onkeyDown)
    }
    return () => {
      document.removeEventListener("keydown", onkeyDown)
    }
  })

  return (
    <Outter
      ref={header}
      menuBg={menuBg?.open}
      variant={variant}
      unPin={unPin}
      unFix={unFix}
      sticky={sticky}
      id={anchorID}
      className="griddo-header"
      onMouseLeave={() => menuBg.open && customSetMenuBg(false, null)}
      onMouseEnter={() => menuBg.open && customSetMenuBg(false, null)}
      data-testid="header"
    >
      <LockBody lock={open} />

      <MaxWidth fluid>
        <Wrapper
          unPin={unPin}
          css={`
            a {
              z-index: 0;
            }
          `}
        >
          {/* Logo (school) */}
          <GriddoLink
            url={{
              linkToURL: getLogoUrl(),
            }}
          >
            {SCHOOLS[school]?.logo &&
              (isMobile ? (
                <Icons
                  display="block"
                  position="relative"
                  name={
                    unPin
                      ? SCHOOLS[school].logoMobile || SCHOOLS[school].logo
                      : menuBg.open
                      ? SCHOOLS[school].logo
                      : SCHOOLS[school][logo] || SCHOOLS[school].logo
                  }
                  title={SCHOOLS[school].name}
                  height={unPin ? "45px" : "60px"}
                  role="img"
                  aria-label={"Logo " + SCHOOLS[school]?.name + ". " + goToHome}
                />
              ) : (
                <Icons
                  display="block"
                  position="relative"
                  name={
                    unPin
                      ? SCHOOLS[school].logo
                      : menuBg.open
                      ? SCHOOLS[school].logo
                      : SCHOOLS[school][logo] || SCHOOLS[school].logo
                  }
                  title={SCHOOLS[school].name}
                  height="60px"
                  role="img"
                  aria-label={"Logo " + SCHOOLS[school]?.name + ". " + goToHome}
                />
              ))}
          </GriddoLink>
          {isMobile ? (
            <>
              <HambMenu unPin={unPin} variant={variant} ml="auto">
                <HambButton
                  unPin={unPin}
                  wrapper
                  onClick={() => setOpen(true)}
                  data-testid="menuButton"
                >
                  <Icons
                    name="openMenu"
                    title={openMenu}
                    height="64px"
                    fill={variant === "transparent" ? "inverse01" : "icons01"}
                    role="img"
                    aria-label={openMenu}
                  />
                </HambButton>
              </HambMenu>
              <MobileMenuContainer display="flex">
                <AnimatePresence mode="wait">
                  {open && (
                    <MobileAnimatedContainer
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          when: "beforeChildren",
                        },
                        closed: {
                          opacity: 0,
                          transition: { delay: 0.6 },
                        },
                      }}
                    >
                      {/* Close button */}
                      <Box ml={"auto"}>
                        <ThemeProvider theme={mobileTheme}>
                          <CloseNav
                            variants={{
                              open: {
                                opacity: 1,
                                transition: { delay: 0.6 },
                              },
                              closed: {
                                opacity: 0,
                              },
                            }}
                            onClick={() => setOpen(false)}
                            data-testid="closeButton"
                          >
                            <Icons
                              name="closeMenu"
                              title={closeMenu}
                              width={{ default: "50px", m: "64px" }}
                              fill="text01"
                              role="img"
                              aria-label={closeMenu}
                            />
                          </CloseNav>
                        </ThemeProvider>
                      </Box>
                      {/* Menu (in mobile)*/}
                      <ThemeProvider theme={mobileTheme}>
                        <NavContainer
                          menuBg={menuBg}
                          isMobile={isMobile}
                          pageLanguages={pageLanguages}
                          languageNames={getTranslation("languageNames")}
                          languageId={languageId}
                          socials={showSocialMedia && socialsArray}
                          primaryLink={primaryLink}
                          secondaryLink={secondaryLink}
                          externalLinks={topMenu?.elements}
                          searchLink={
                            botUrl &&
                            showBotIcon && {
                              label: "Search",
                              url: { href: botUrl, newTab: true },
                            }
                          }
                          mainMenu={mainMenu}
                          variant={variant}
                          unPin={unPin}
                          unFix={unFix}
                          navVariants={{
                            open: {
                              x: 0,
                              transition: {
                                duration: 0.7,
                              },
                            },
                            closed: {
                              x: "100%",
                              transition: {
                                delay: 0.5,
                                duration: 0.5,
                              },
                            },
                          }}
                          listVariants={{
                            open: {
                              transition: {
                                delayChildren: 0.5,
                                staggerChildren: 0.1,
                              },
                            },
                            closed: {
                              transition: {
                                staggerChildren: 0.06,
                                staggerDirection: -1,
                              },
                            },
                          }}
                          itemVariants={{
                            open: {
                              x: 0,
                              opacity: 1,
                              transition: {
                                y: { stiffness: 1000 },
                              },
                            },
                            closed: {
                              x: 120,
                              opacity: 0,
                              transition: {
                                y: { stiffness: 1000 },
                              },
                            },
                          }}
                        />
                      </ThemeProvider>
                    </MobileAnimatedContainer>
                  )}
                </AnimatePresence>
              </MobileMenuContainer>
            </>
          ) : (
            <NavigationWrapper display="flex">
              <NavContainer
                mainMenu={mainMenu}
                variant={variant}
                unPin={unPin}
                primaryLink={primaryLink}
                secondaryLink={secondaryLink}
                menuBg={menuBg}
                customSetMenuBg={customSetMenuBg}
                isActiveLink={isActiveLink}
              />
            </NavigationWrapper>
          )}
        </Wrapper>
      </MaxWidth>
      <DesktopSubMenuWrapper menuBg={menuBg?.open}>
        <DesktopSubMenu
          menuItem={
            (mainMenu?.elements && mainMenu?.elements[menuBg?.element]) || null
          }
          isActiveLink={isActiveLink}
        />
      </DesktopSubMenuWrapper>

      {SCHOOLS[school]?.logo && school === "IENYC" ? (
        <LdJson
          data={{
            "@context": "https://schema.org",
            "@type": "CollegeOrUniversity",
            name: "IE IENYC",
            alternateName: "IE New York City",
            url: "https://www.ie-nyc.org/",
            logo: `https://thesaurus.ie.edu/logos/logos/${SCHOOLS[school]?.logo}.png`,
            email: "info@ienyc.ie.edu",
            telephone: "+16467685300",
            address: [
              {
                "@type": "PostalAddress",
                streetAddress:
                  "64 Wooster St, New York, NY 10012, United States",
                addressLocality: "New York",
                postalCode: "10012",
                addressCountry: "US",
              },
            ],
            sameAs: [
              "https://www.facebook.com/IENewYorkCollege",
              "https://www.youtube.com/@ienewyorkcollege",
              "https://x.com/ie_nyc",
              "https://www.linkedin.com/school/ienyc/",
              "https://www.instagram.com/ienewyorkcollege/",
            ],
          }}
        />
      ) : (
        <LdJson
          data={{
            "@context": "https://schema.org",
            "@type": "CollegeOrUniversity",
            name: "IE University",
            alternateName: "Instituto de Empresa",
            url: `${fullPath?.domainUrl}${fullPath?.language || "/"}`,
            logo: `https://thesaurus.ie.edu/logos/logos/${SCHOOLS[school]?.logo}.png`,
            email: "info@ie.edu",
            telephone: "+34 91 568 96 00",
            address: [
              {
                "@type": "PostalAddress",
                streetAddress:
                  "P.º de la Castellana, 259, Fuencarral-El Pardo, 28046 Madrid",
                addressLocality: "Madrid",
                postalCode: "28029",
                addressCountry: "ES",
              },
              {
                "@type": "PostalAddress",
                streetAddress: "Calle de María de Molina, 11-13-15",
                addressLocality: "Madrid",
                postalCode: "28006",
                addressCountry: "ES",
              },
              {
                "@type": "PostalAddress",
                streetAddress: "C. Cardenal Zúñiga, 12",
                addressLocality: "Segovia",
                postalCode: "40003",
                addressCountry: "ES",
              },
            ],
            sameAs: [
              "https://www.facebook.com/IEuniversity/",
              "https://www.youtube.com/@IEUniversity",
              "https://x.com/IEuniversity",
              "https://www.linkedin.com/school/ie-university/",
              "https://www.instagram.com/ieuniversity/",
              "https://en.wikipedia.org/wiki/IE_University",
            ],
            department: [
              {
                "@type": "EducationalOrganization",
                name: "IE School of Science and Technology",
                url: "https://www.ie.edu/school-science-technology/",
              },
              {
                "@type": "EducationalOrganization",
                name: "IE School of Politics, Economics and Global Affairs",
                url: "https://www.ie.edu/school-politics-economics-global-affairs/",
              },
              {
                "@type": "EducationalOrganization",
                name: "IE School of Humanities",
                url: "https://www.ie.edu/school-of-humanities/",
              },
              {
                "@type": "EducationalOrganization",
                name: "IE School of Architecture and Design",
                url: "https://www.ie.edu/school-architecture-design/",
              },
              {
                "@type": "EducationalOrganization",
                name: "IE Business School",
                url: "https://www.ie.edu/business-school/",
              },
              {
                "@type": "EducationalOrganization",
                name: "IE University",
                url: "https://www.ie.edu/university/",
              },
            ],
          }}
        />
      )}
    </Outter>
  )
}

export default MenuContent
